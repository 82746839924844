<template>
  <div class="mobile-product">
    <div class="mobile-product-bg" :class="{ 'eye-bg': menuActive === 2 }"></div>
    <div class="mobile-product-container">
      <div class="mobile-product-header">
        <div class="title">
          <span>iLaw 产品体系</span>
          <span>智能·协同·高效数字化产品</span>
        </div>
        <div class="remake">
          <div>融合法律智慧与科技力量 | 为企业打造一站式合</div>
          <div>规解决方案 | 助力企业法务合规业务数字化</div>
        </div>
      </div>
      <div class="mobile-product-menu">
        <div v-for="(item, index) in menuList" :key="index" class="mobile-product-menu-item"
          :class="{ 'mobile-product-menu-item-active': index === menuActive }" @click="menuActive = index">
          <div class="mobile-product-menu-tab">{{ item.name }}</div>
        </div>
      </div>
      <div class="mobile-product-content">
        <div class="mobile-product-content-card">
          <template v-if="[2, 5].includes(menuActive)">
            <!-- 合规眼 -->
            <div class="mobile-product-item-card">
              <div class="eye-title" v-for="(item, index) in menuList[menuActive].children" :key="index">
                <img class="icon" src="@/images/newHome/reservation/icon.png" alt="">
                <div class="text">{{ item }}</div>
              </div>
              <div class="bottom border-line" v-if="menuActive === 2">
                <div @click="jumpToUrl('https://hgb.heguixueyuan.com/')">
                  前往官网</div>
                <div @click="jumpToUrl('https://hgb.heguixueyuan.com/login')">
                  立即登录</div>
                <div @click="goForm">立即咨询</div>
              </div>
              <div class="solution-bottom" v-else>
                <img class="img-1" src="@/images/newHome/qr-code.png" alt="">
                <img class="img-2" src="@/images/newHome/new-ilaw-logo.png" alt="">
                <div class="text-1">定制合规解决方案</div>
                <div class="text-2">了解更多合规解决方案</div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="mobile-product-item-card" v-for="(item, index) in menuList[menuActive].children" :key="index">
              <div class="title">
                {{ item.name }}
              </div>
              <div class="bottom">
                <div v-if="item.action.includes('website')" @click="jumpToUrl(item.officialWebsite, 'website')">
                  前往官网</div>
                <div v-if="item.action.includes('login')" @click="jumpToUrl(item.loginUrl, 'login')">
                  立即登录</div>
                <div @click="goForm" v-if="item.action.includes('seek')">立即咨询</div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <MobileFooter></MobileFooter>
  </div>
</template>

<script>
import MobileFooter from '@/components/mobile-footer';
export default {
  components: {
    MobileFooter
  },
  data() {
    return {
      menuActive: 0,
      menuList: [
        {
          name: '达芬奇法务综合平台',
          children: [
            {
              name: '达芬奇·智能合同系统',
              action: ['website', 'login', 'seek'],
              officialWebsite: "https://contract.dafenqi.law/web-site",
              loginUrl: "https://contract.dafenqi.law/login",
            },
            {
              name: '达芬奇·智能案件管理系统',
              action: ['website', 'login', 'seek'],
              officialWebsite: "https://legal.dafenqi.law/web-site",
              loginUrl: "https://legal.dafenqi.law/web-site/login",
            },
            {
              name: '达芬奇·制度管理平台',
              action: ['seek'],
            },
            {
              name: '达芬奇·证照管理平台',
              action: ['seek'],
            },
            {
              name: '达芬奇·法务课堂平台',
              action: ['login', 'seek'],
              loginUrl: "https://app.lawtrust.cn/login",
            }
          ]
        },
        {
          name: '专项合规治理平台',
          children: [
            {
              name: '数据合规',
              action: ['website', 'login', 'seek'],
              officialWebsite: "https://lawtrust.cn/homePage",
              loginUrl: "https://app.lawtrust.cn/login"
            },
            {
              name: '劳动用工合规',
              action: ['login', 'seek'],
              loginUrl: "https://labor.lawtrust.cn/login"
            },
            {
              name: 'ESG(环境、社会和治理)',
              action: ['website', 'seek'],
              officialWebsite: "https://esg.ilaw.law/",
            },
            {
              name: '广告营销合规',
              action: ['seek'],
            },
            {
              name: '财税合规',
              action: ['seek'],
            },
            {
              name: '知识产权合规',
              action: ['seek'],
            },
            {
              name: '公司治理',
              action: ['seek'],
            },
            {
              name: '贸易合规',
              action: ['seek'],
            },
            {
              name: '反垄断&反不当竞争合规',
              action: ['seek'],
            },
            {
              name: '反腐败&反商业贿赂',
              action: ['seek'],
            },
            {
              name: '产品质量',
              action: ['seek'],
            },
            {
              name: '采购合规',
              action: ['seek'],
            },
            {
              name: '消费者权益',
              action: ['seek'],
            },
          ]
        },
        {
          name: 'EyeTrust合规眼系统',
          children: [
            "面向全员的合规尽调",
            "科学的风险识别与评估",
            "有针对性的合规培训",
            "全员在线签署《员工合规承诺书》",
            "强证明力的依法公证存证",
          ],
        },
        {
          name: '企业级法律AI',
          children: [
            {
              name: "AI合同审查",
              action: ['website', 'login', 'seek'],
            },
            {
              name: "AI合同履约",
              action: ['website', 'login', 'seek'],
            },
            {
              name: "AI合规监管态势情报",
              action: ['website', 'login', 'seek'],
            },
            {
              name: "AI案情分析",
              action: ['website', 'login', 'seek'],
            },
            {
              name: "AI合规业务咨询",
              action: ['website', 'login', 'seek'],
            },
            {
              name: "AI智能检索",
              action: ['website', 'login', 'seek'],
            },
            {
              name: "AI业务审核",
              action: ['website', 'login', 'seek'],
            },
            {
              name: "AI文书写作",
              action: ['website', 'login', 'seek'],
            },
          ],
        },
        {
          name: '法律大数据平台',
          children: [
            { name: "案例库", action: ['login', 'seek'] },
            { name: "法规库", action: ['login', 'seek'] },
            { name: "行政处罚库", action: ['login', 'seek'] },
            { name: "检查文书库", action: ['login', 'seek'] },
            { name: "实务文章库", action: ['login', 'seek'] },
            { name: "司法观点库", action: ['login', 'seek'] },
            { name: "优案评析库", action: ['login', 'seek'] },
            { name: "类案同判库", action: ['login', 'seek'] },
            { name: "公司主体库", action: ['login', 'seek'] },
            { name: "期刊论文库", action: ['login', 'seek'] },
          ],
        },
        {
          name: '定制化合规解决方案',
          children: [
            "专业合规专家1V1交流",
            "企业专属个性化解决方案",
            "一体化法律合规解决方案",
            "高效便捷的智能办公体验",
            "免费体验系统功能",
            "专业团队答疑解惑",
          ],
        }
      ]
    }
  },
  methods: {
    jumpToUrl(url, type) {
      if (this.menuActive === 3) {
        if (type === 'website') {
          window.open('https://ai.ilaw.law/');
        } else {
          window.open('https://ai.ilaw.law/login');
        }
        return;
      } else if (this.menuActive === 4) {
        window.open("https://ecdb.lawtrust.cn/login");
        return;
      }
      window.open(url);
    },
    goForm() {
      const url = window.location.origin;
      window.open(`${url}/appointment`);
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/pages/css/mobile-base.scss";

.mobile-product {
  width: 100vw;
  position: relative;
  // background-color: #d2e1f8;
  background: linear-gradient(to right, #b7d6f5, #D2E1F8);

  .mobile-product-bg {
    background-image: url("../../images/newHome/product/product-bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    width: 100%;
    height: px-to-vw(718);
    position: absolute;
    top: 0;
    left: 0;
  }

  .eye-bg {
    height: px-to-vw(518);
  }

  .mobile-product-container {
    width: 100%;
    height: 100%;
    position: relative;
    // background-image: url("../../images/newHome/product/product-bg.png");
    // background-size: 100% 100%;
    // background-repeat: no-repeat;
    // background-position: center;

    .mobile-product-header {
      .title {
        display: flex;
        flex-direction: column;
        padding-top: px-to-vw(60);
        line-height: px-to-vw(32);

        &>span:nth-child(1) {
          font-size: px-to-vw(26.28);
          margin-bottom: px-to-vw(15);
          font-weight: 700;
          font-family: PingFang SC;
        }

        &>span:nth-child(2) {
          font-size: px-to-vw(21);
          font-weight: 500;
          margin-bottom: px-to-vw(20);
        }
      }

      .remake {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: px-to-vw(12);
        margin-bottom: px-to-vw(41);
        font-weight: 400;

        &>div:nth-child(1) {
          margin-bottom: px-to-vw(5);
        }
      }
    }

    .mobile-product-menu {
      overflow-x: auto;
      display: flex;
      padding-bottom: px-to-vw(10);

      .mobile-product-menu-item {
        margin-left: px-to-vw(20);
        background-color: #ffffff;
        color: #1D2129;
        border-radius: px-to-vw(24);
        padding: 0 px-to-vw(15);
        height: px-to-vw(36);
        line-height: px-to-vw(36);
        text-align: center;

        .mobile-product-menu-tab {
          white-space: nowrap;
          font-size: px-to-vw(14);
        }
      }

      .mobile-product-menu-item-active {
        background-color: #0083C9;
        color: #ffffff;
      }

      .mobile-product-menu-item:last-child {
        margin-right: px-to-vw(20);
      }
    }

    .mobile-product-content {
      width: 100%;
      padding: px-to-vw(30) px-to-vw(20);
      padding-top: px-to-vw(20);
      box-sizing: border-box;

      .mobile-product-content-card {
        background-color: #F4F8FF;
        border-radius: px-to-vw(8);
        padding: px-to-vw(20) px-to-vw(15);

        .mobile-product-item-card {
          background-color: #ffffff;
          border-radius: px-to-vw(8);
          padding: 0 px-to-vw(15);
          margin-bottom: px-to-vw(20);

          .eye-title {
            display: flex;
            align-items: center;
            padding-bottom: px-to-vw(17.52);
            line-height: px-to-vw(22.77);

            .icon {
              width: px-to-vw(15);
              height: px-to-vw(15);
              margin-right: px-to-vw(8);
            }

            .text {
              font-size: px-to-vw(14);
              font-family: PingFang SC;
              font-weight: 500;
            }
          }

          .eye-title:first-child {
            padding-top: px-to-vw(14);
          }

          .border-line {
            border-top: 0.5px solid #E7E8EA;
          }

          .title {
            font-size: px-to-vw(14);
            font-family: PingFang SC;
            font-weight: 500;
            line-height: px-to-vw(22.77);
            color: #11192B;
            padding-top: px-to-vw(14);
            padding-bottom: px-to-vw(12);
            border-bottom: 0.5px solid #E7E8EA;
            text-align: left;
          }

          .bottom {
            height: px-to-vw(23.65);
            // display: flex;
            display: grid;
            // grid-template-columns: repeat(3, 1fr);
            grid-auto-columns: 1fr;
            /* 所有列等宽 */
            grid-auto-flow: column;
            /* 横向排列 */
            // justify-content: center;
            // align-items: center;
            color: #0083C9;
            padding: px-to-vw(10.5) 0;
            font-size: px-to-vw(12);

            &>div:not(:first-child) {
              border-left: 0.5px solid #E7E8EA;
            }

            &>div {
              line-height: px-to-vw(23.65);
              padding: 0 px-to-vw(20);
              white-space: nowrap;
            }
          }

          .solution-bottom {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: px-to-vw(20);

            .img-1 {
              width: px-to-vw(120);
              height: px-to-vw(120);
              margin-top: px-to-vw(20);
              margin-bottom: px-to-vw(30);
            }

            .img-2 {
              width: px-to-vw(110);
              margin-bottom: px-to-vw(30);
            }

            .text-1 {
              font-size: px-to-vw(16);
              color: #11192B;
              font-weight: 600;
              font-family: PingFang SC;
              margin-bottom: px-to-vw(10);
            }

            .text-2 {
              font-size: px-to-vw(10.5);
              color: #656A73
            }
          }
        }

        .mobile-product-item-card:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>